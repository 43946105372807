.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #721c24;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: #721c24;
    border: 0;
  }
  .close:focus, .close:hover {
    /* color: #fff; */
    opacity: .75;
    text-decoration: none;
    background-color: #721c24;
  }
  .img-thumbnail {
    width: 100px;
    height: 100px;
  }

  .image-preview-container {
    /* Set a fixed height and width for the container */
    height: 180px;
    width: 100%; /* Width is set to fit 3 images (120px each) and their margins (2 * 5px each) */
    
    /* Add scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    /* overflow-x: auto; */
  white-space: nowrap;
  }
  
  .image-preview-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .image-preview-container::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  
  .image-preview-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
  }
  
  .image-preview-thumbnail-container {
    width: 80px;
    
    height: 80px;
    margin-right: 10px; /* Add margin between thumbnails */
  }
  
  .image-preview-thumbnail {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  /* .image-preview-close-button {
    z-index: 3;
  } */
  
  .no-images-preview {
    font-size: 12px;
    color: #999;
  }