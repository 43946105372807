.Playground-right_card {
    justify-content: left;
}
@media (min-width: 768px) {
    .Playground-right_card {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.playground-left-card {
    max-width: 50%;
}
