.match-bio {
    padding: 10px;
  }

.match-bio-container {
    display: flex;
    /* flex-direction: column; */
    height: 50vh;

}

  
.match-bio-rectangle {
    
    
    height: fit-content;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
    padding: 20px; /* adds 20px padding to left and right, and 30px padding to top and bottom */
  }
